<template>
 <div class="del-table">
    <avue-crud
        v-bind="bindVal"
        v-on="onEvent"
        v-model="form"
        :page.sync="page">
        
    </avue-crud>
 </div>
</template>

<script>
let crud = window.$crudCommon({}, {
  name: "log/del-img",
  pageNumber: 'current',//页码
  pageSize: 'size',//页数
  total: 'total',//总页数
  res:(data)=>{
    return {
      total: data.total,
      data: data.records
    }
  },//列表的结构
})
export default {
  name: '',
  components: {
  },
  mixins: [crud],
  props: {
    className: {
      type: String,
      default: 'chart'
    },
  },
  data () {
    return {
      userId: undefined,
    }
  },
  watch: {
    // options: {
    //   handler (options) {
    //     // 设置true清空echart缓存
    //     this.chart.setOption(options, true)
    //   },
    //   deep: true
    // }
  },
  mounted () {
  },
  beforeDestroy () {
  },
  methods: {
   
  }
}
</script>

<style>
  .del-table {
    /* padding: 0 25px 0 25px; */
    color: white !important;;
  }
  .avue-group{
    padding-top: 10px;
  }
  .avue-crud__menu {
    padding: 10px;
  }
  .el-card__body {
    background: transparent !important;
  }
  /* .el-table, .el-table__expanded-cell {
    background: #0f1325 !important;
  } */
  .el-table--border, .el-table--group {
    border:  none !important;
  }
  .el-button--primary {
    background-color: #e94e46;
    border-color: #e94e46;
  }
  .el-button--primary:focus, .el-button--primary:hover {
    background-color: #e94e46;
    border-color: #e94e46;
    opacity: 0.7;
  }
  .el-button--text {
    color: #e94e46;
  }
  .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #e94e46;
  }
  /* .el-table tr{
      margin: 0;
      padding: 0;
      height: 50px;
  }
  .el-table thead tr{
      background: rgba(5, 58, 152, 0.5);
  }
  .el-table tbody .row-class-fix td:first-child{
      border-left: 1px solid #053A98;
  }
  .el-table td{
      border-bottom: 1px solid #053A98;
      border-right: 1px solid #053A98;
  } */
</style>
